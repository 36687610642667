.art-gallery-app {
    text-align: center;
    direction: rtl;
  }
  
  .art-gallery-header {
    background-color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .art-gallery-logo {
    color: white;
    font-size: 1.5em;
    margin-left: 40px;
  }
  
  .art-gallery-nav {
    margin-right: 20px;
  }
  
  .art-gallery-nav-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .art-gallery-nav-list li {
    margin-left: 10px;
  }
  
  .art-gallery-nav-list li a {
    color: white;
    padding: 14px 16px;
    text-decoration: none;
  }
  
  .art-gallery-nav-list li a:hover {
    background-color: #111;
  }
  
  .art-gallery-main {
    padding: 20px;
  }
  
  .art-gallery-title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 40px;
    margin-top: 40px;

    text-align: center;
  }
  
  .art-gallery-image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .art-gallery-main-image {
    width: auto;
    height: auto;
    width: 500px;
    height: 500px;
  }
  
  .art-gallery-artist-info {
    text-align: right;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 40px;
  }
  
  .art-gallery-artworks {
    margin-top: 60px;
    text-align: center;
  }
  
  .art-gallery-artworks-title {
    font-size: 1.5em;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .art-gallery-artwork-grid {
    display: flex;
    justify-content:center;
    align-items: center;
  }
  
  .art-gallery-artwork {
    text-align: center;
  }
  
  .art-gallery-artwork img {
    width: 300px;
    height: 300px;
    border-radius: 5px;
  }
  
  .art-gallery-artwork p {
    margin-top: 10px;
  }
  
  .art-gallery-more-link {
    text-decoration: none;
    color: #000;
  }
  
  .info {
    margin-top: 20px;
  }
  
  .info-item {
    margin-bottom: 20px;
  }
  
  .info-item {
    display: block;
    margin-bottom: 5px;
    font-size: 18px;
  }
  
  .info-item p {
    margin: 0;
    font-size: 16px;
    line-height: 1.6;
  }
  
  .info-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .info-table th,
  .info-table td {
    padding: 8px;
  }
  
  .info-table th {
    font-weight: bold;
  }
  
  .info-long-text {
    display: block;
    margin-top: 10px;
    text-align: right; /* Align text to the right for Arabic content */
  }
  
  