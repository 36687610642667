.footer-122 {
  background: linear-gradient(to bottom right, #002F6B, #247DBD); /* Gradient background */
  color: white;
  text-align: center;
  padding: 20px 0;
  margin: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
flex-direction: column;
}

.footer-links {
  margin: 10px 0;
}

.footer-links a {
  color: white;
  text-decoration: none;
  margin: 0 15px;
}

.footer-links a:hover {
  text-decoration: underline;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.form input, .form textarea {
  width: 90%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.form button {
  padding: 10px 20px;
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}
.footer-contacts {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 10px;
  width: 85%;
}
.footer-contacts p{
  color: white;
}
.footer-contacts h3{
  text-decoration: underline;

}
.contact-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 10px;
}
