.contact-us {
    padding: 50px 20px;
  }
  
  .contact-us h2 {
    margin-bottom: 20px;
    font-size: 2em;
    text-align: center;
  }
  
  .contact-info {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
  }
  
  .contact-item {
    text-align: center;
    width: 30%;
  }
  
  .contact-item img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .contact-item p {
    margin: 5px 0;
    color: #555;
  }
  
