
  
  

   .about-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 40px;
   }
  